<template>

    <!-- Structure -->
    <v-container fluid>
    
        <!-- Toolbar -->
        <v-toolbar class="mb-5">
            <v-badge color="green darken-3" class="hidden-xs-only" :content="filter_length" offset-y="15" :value="filter_length">
                <v-toolbar-title>Catalogue</v-toolbar-title>
            </v-badge>
            <v-spacer class="hidden-xs-only"></v-spacer>
            <v-text-field
                append-icon="search"
                clearable
                label="Rechercher"
                hide-details
                single-line
                v-model="filter_search"
                @click="clear_expand">
            </v-text-field>
            <v-spacer></v-spacer>
            <v-btn-toggle
                dense
                mandatory
                v-model="display_cards">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="green darken-3"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :value="false"
                                @click="clear_expand">
                                    <v-icon color="green darken-3">mdi-view-list</v-icon>
                            </v-btn>
                        </template>
                        <span>Affichage liste</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="green darken-3"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :value="true"
                                @click="clear_expand">
                                    <v-icon color="green darken-3">mdi-view-grid</v-icon>
                            </v-btn>
                        </template>
                    <span>Affichage grille</span>
                </v-tooltip>
            </v-btn-toggle>  
            <v-tooltip bottom v-if="filtering">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon v-bind="attrs" v-on="on" @click="clear_filters">
                        <v-icon>mdi-filter-remove</v-icon>
                    </v-btn>
                </template>
                <span>Supprimer les filtres</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!filter_menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon v-bind="attrs" v-on="on" @click="filter_menu = !filter_menu">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </template>
                <span>Afficher les filtres</span>
            </v-tooltip>
        </v-toolbar>
        
        <!-- Menu des filtres -->
        <v-bottom-sheet inset v-model="filter_menu">
            <v-card flat color="grey lighten-4" rounded="0">
                <v-card-title>
                    Filtres
                </v-card-title>
                <v-card-text class="pt-0">
                    <v-row align="center" no-gutters>
                        <v-col :cols="Math.max(12/Math.max(per_row/2, 1), 6) === 12 ? '9' : Math.max(12/Math.max(per_row/2, 1), 6)">
                            <v-select
                                dense
                                hide-details
                                :items="filter_keys"
                                label="Trier par"
                                outlined
                                v-model="filter_sort_by"
                                @click="clear_expand">
                            </v-select>
                        </v-col>
                        <v-col class="pl-5" :cols="Math.max(12/Math.max(per_row/2, 1), 6) === 12 ? '3' : (12 - Math.max(12/Math.max(per_row/2, 1), 6))">
                            <v-btn-toggle
                                dense
                                mandatory
                                v-model="filter_sort_desc">
                                    <v-btn
                                        color="green darken-3"
                                        icon
                                        :value="false"
                                        @click="clear_expand">
                                            <v-icon color="green darken-3">mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="green darken-3"
                                        icon
                                        :value="true"
                                        @click="clear_expand">
                                            <v-icon color="green darken-3">mdi-arrow-down</v-icon>
                                    </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <v-row align="center" class="pt-10" no-gutters>
                        <v-col :cols="Math.max(12/Math.max(per_row/2, 1), 6) === 12 ? '9' : Math.max(12/Math.max(per_row/2, 1), 6)">
                                <v-slider 
                                    append-icon="mdi-plus-circle"
                                    label="Durée max."
                                    min="10" max="120"
                                    prepend-icon="mdi-minus-circle"
                                    step="10"
                                    thumb-label="always"
                                    v-model="filter_duration"
                                    @click="clear_expand"
                                    @click:append="increase_filter_duration"
                                    @click:prepend="decrease_filter_duration">
                                        <template v-slot:thumb-label="{ value }">
                                            {{ display_duration(value) }}
                                        </template>
                                </v-slider>
                        </v-col>
                    </v-row>
                    <v-row align="center" no-gutters>
                        <v-col :cols="Math.max(12/Math.max(per_row/2, 1), 6) === 12 ? '9' : Math.max(12/Math.max(per_row/2, 1), 6)">
                                <v-checkbox label="En stock uniquement" v-model="filter_disponible"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-spacer></v-spacer>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="green darken-3"
                                    dark
                                    text
                                    v-bind="attrs"
                                    v-on="on">
                                        {{ filter_per_page }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    :key="index"
                                    v-for="(number, index) in filter_per_page_arr" 
                                    @click="per_page_update(number)">
                                        <v-list-item-title>{{ number }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <span>par page</span>
                        <span class="ml-2">-</span>
                        <span class="ml-2">page {{ current_page }} sur {{ pages }}</span>
                        <v-btn
                            class="ml-2"
                            color="green darken-3"
                            dark
                            icon
                            @click="previous_page">
                                <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                            color="green darken-3"
                            dark
                            icon
                            @click="next_page">
                                <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
        
        <!-- Collection - Affichage grille -->
        <v-data-iterator
            :custom-filter="custom_filters"
            hide-default-footer
            :items="items"
            item-key="nom"
            :items-per-page.sync="filter_per_page"
            :page="current_page"
            :search="filter_search"
            :sort-by="clear_accent(filter_sort_by.toLowerCase())"
            :sort-desc="filter_sort_desc"
            v-if="display_cards">
                <template v-slot:header>
                </template>
                <template v-slot:default="{ items }">
                    <v-row dense :key="row.index" v-for="row in rows(items)">
                        <v-col
                            v-for="(item, index) in structure(items, row, per_row)"
                            :key="index"
                            :cols="12/per_row">
                                <v-card :disabled="!item.disponible" hover outlined rounded="0" @click="{expand(item, row, index)}">
                                    <v-img
                                        height="100px"
                                        :src="require(`@/assets/img/${item.image}`)">
                                    </v-img>
                                    <v-card-title>
                                        {{ item.nom }}
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-icon color="green darken-3">{{is_expanded(item, row, index) ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon>
                                        <v-spacer></v-spacer>
                                        <v-icon color="green darken-3">{{is_expanded(item, row, index) ? 'mdi-playlist-remove' : 'mdi-playlist-plus'}}</v-icon>
                                    </v-card-actions>
                                </v-card>
                        </v-col>
                        <v-col class="mt-0" cols="12" v-if="expanded_row === row">
                            <v-card dense flat outlined rounded="0" v-bind="expanded_item">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr :key="index" v-for="(key, index) in keys">
                                                <td :class="{ 'blue--text': filter_sort_by === clear_accent(key.toLowerCase()) }" width="20%">
                                                    {{ key }}
                                                </td>
                                                <td :class="{ 'blue--text': filter_sort_by === clear_accent(key.toLowerCase()) }">
                                                    <v-checkbox
                                                        disabled
                                                        v-model="expanded_item[clear_accent(key.toLowerCase())]"
                                                        v-if="typeof expanded_item[clear_accent(key.toLowerCase())] === 'boolean'">
                                                    </v-checkbox>
                                                    <span
                                                        v-if="typeof expanded_item[clear_accent(key.toLowerCase())] !== 'boolean'">
                                                            {{ expanded_item[clear_accent(key.toLowerCase())] }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>    
                                    </template>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:no-results>
                    <v-alert 
                        border="left"
                        class="mt-5"
                        dense
                        text
                        type="error"
                        >Votre recherche <kbd v-if="!filtering && searching" v-html="filter_search"></kbd> n'a donné aucun résultat.
                    </v-alert>
                </template>
        </v-data-iterator>
        
        <!-- Collection - Affichage liste -->
        <v-card :loading="loading" v-if="!display_cards">
            <v-card-text>
                <v-alert 
                    border="left"
                    dense 
                    dismissible
                    text 
                    transition="fade-transition"
                    type="error"
                    v-model="error_alert">
                        {{ error }}
                </v-alert>
                <v-alert 
                    border="left"
                    dense 
                    dismissible
                    text 
                    transition="fade-transition"
                    type="warning"
                    v-model="warning_alert">
                        {{ warning }}
                </v-alert>
                <v-alert 
                    border="left"
                    dense 
                    dismissible
                    text 
                    transition="fade-transition"
                    type="success"
                    v-model="message_alert">
                        {{ message }}
                </v-alert>
                <v-data-table
                    :custom-filter="custom_filters_table"
                    dense
                    :expanded.sync="expanded_table"
                    :footer-props="{
                        itemsPerPageOptions: this.filter_per_page_arr
                    }"
                    :headers="headers"
                    item-key="nom"
                    :items="items"
                    :items-per-page.sync="filter_per_page"
                    :page="current_page"
                    :search="filter_search"
                    single-expand
                    show-expand
                    :sort-by="clear_accent(filter_sort_by.toLowerCase())"
                    :sort-desc="filter_sort_desc">
                        <template v-slot:item.disponible="{ item }">
                            <v-simple-checkbox disabled v-model="item.disponible"></v-simple-checkbox>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                {{ item.resume }}
                            </td>
                        </template>
                        <template v-slot:no-results>
                            <v-alert border="left" class="mt-5" dense text transition="fade-transition" type="error">
                                Votre recherche <kbd v-if="!filtering && searching" v-html="filter_search"></kbd> n'a donné aucun résultat.
                            </v-alert>
                        </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    
    </v-container>

</template>

<script>

// Import éventuel d'autres composants - les rajouter dans 'components' et les appeler par balise : <roles></roles>
// import roles from "./Roles"

export default {
    name: 'home',
    components: {
        //roles
    },
    data() {
        return {
            display_cards: true,
            expanded: false,
            expanded_col: 0,
            expanded_row: 0,
            expanded_item: {},
            expanded_table: [],
            filter: {},
            filter_disponible: false,
            filter_duration: 120,
            filter_menu: false,
            filter_page: 1,
            filter_per_page: 12,
            filter_per_page_arr: [4, 8, 12],
            filter_players: 0,
            filter_sort_by: 'nom',
            filter_sort_desc: false,
            filter_sort_keys: ['age', 'duree', 'joueurs', 'nom'],
            filter_search: '',
            headers: [
                {
                    filter: value => {
                        const formated_search = this.filter_search ? this.clear_accent(this.filter_search.toLowerCase()) : null
                        return !formated_search || value.toLowerCase().includes(formated_search)
                    },
                    filterable: true,
                    sortable: false,
                    text: 'Nom',
                    value: 'nom'
                },
                { 
                    filterable: true,
                    sortable: false,
                    text: 'Joueurs', 
                    value: 'joueurs' 
                },
                { 
                    filterable: true,
                    sortable: false,
                    text: 'Age', 
                    value: 'age' 
                },
                { 
                    filterable: true,
                    sortable: false,
                    text: 'Lieu', 
                    value: 'lieu' 
                },
                { 
                    filterable: true,
                    filter: value => {
                        return !this.filter_disponible || value === this.filter_disponible
                    },
                    sortable: false,
                    text: 'Disponible', 
                    value: 'disponible' 
                },
                {
                    align: 'end',
                    filter: value => {
                        return !this.filter_duration || value <= this.filter_duration
                    },
                    filterable: true,
                    sortable: false,
                    text: 'Durée',
                    value: 'duree'
                }
            ],
            items: [
                 {
                  nom: 'Aventuriers du Rail',
                  joueurs: [3,5],
                  age: 12,
                  duree: 45,
                  tags: ['Jeu de plateau'],
                  edition: 2005,
                  resume: "Dans ce jeu, replongez dans l'âge d'or du chemin de fer. Lancez-vous à la conquête du rail et tentez de prendre le contrôle du réseau ferroviaire américain en reliant un maximum de villes entre elles.",
                  lieu: 'Monestier',
                  disponible: true,
                  image: "AventuriersDuRail_Europe.png"
                },
                {
                  nom: 'Aventuriers du Rail 2',
                  joueurs: [2,5],
                  age: 6,
                  duree: 45,
                  tags: ['Jeu de plateau'],
                  edition: 2005,
                  resume: "Dans ce jeu, replongez dans l'âge d'or du chemin de fer. Lancez-vous à la conquête du rail et tentez de prendre le contrôle du réseau ferroviaire américain en reliant un maximum de villes entre elles.",
                  lieu: 'Mens',
                  disponible: false,
                  image: "AventuriersDuRail_Europe.png"
                },
                {
                  nom: 'Bazar Bizarre',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "BazarBizarre.png"
                },
                {
                  nom: 'Carcassonne',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "Carcassonne.png"
                },
                {
                  nom: 'Dungeon Twister',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "DungeonTwister.png"
                },
                {
                  nom: 'Elasund',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "AventuriersDuRail_Europe.png"
                },
                {
                  nom: 'Fantasy',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "AventuriersDuRail_Europe.png"
                },
                {
                  nom: 'G',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "AventuriersDuRail_Europe.png"
                },
                {
                  nom: 'H',
                  joueurs: [2,8],
                  age: 8,
                  duree: 120,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "AventuriersDuRail_Europe.png"
                },
                {
                  nom: 'Iquazu',
                  joueurs: [2,8],
                  age: 8,
                  duree: 60,
                  tags: ['Observation', 'Jeu de cartes'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "Iquazu.png"
                },
                {
                  nom: 'Jungle Speed',
                  joueurs: [2,8],
                  age: 8,
                  duree: 20,
                  tags: ['Rapidité', 'Jeu d\'adresse'],
                  edition: 2005,
                  resume: 'Lorem ipsum',
                  lieu: 'Monestier',
                  disponible: true,
                  image: "AventuriersDuRail_Europe.png"
                }
            ],
            keys: [
                'Nom',
                'Joueurs',
                'Âge',
                'Durée',
                'Tags',
                'Edition',
                'Résumé',
                'Lieu',
                'Disponible',
                'Image',
            ]
        }
    },
    computed: {
        columns() {
            return Math.ceil(12 / this.per_row)
        },
        current_page() {
            return this.pages === 0 ? 0 : Math.min(this.filter_page, this.pages)
        },
        dialog_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '90%'
                case 'sm': return '80%'
                case 'md': return '75%'
                case 'lg': return '70%'
                case 'xl': return '50%'
                default: return '75%'
            }
        },
        filter_keys () {
            return this.keys.filter(key => this.filter_sort_keys.includes(this.clear_accent(key.toLowerCase())))
        },
        filter_length() {
            return this.custom_filters(this.items, this.filter_search).length;
        },
        filtering() {
            return (this.filter_duration !== 120 || this.filter_per_page !== 12 || this.filter_sort_by !== 'nom' || this.filter_sort_desc !== false || this.filter_disponible !== false);
        },
        pages() {
            return Math.ceil(this.filter_length / this.filter_per_page)
        },
        per_row() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 1
                case 'sm': return 2
                case 'md': return 3
                case 'lg': return 4
                case 'xl': return 6
                default: return 4
            }
        },
        searching() {
            return (this.filter_search !== null && this.filter_search !== '')
        }
    },       
    watch: {
        per_row: 'clear_expand'
    },
    methods: {
        clear_accent(text){
            var rules = {
                a:"àáâãäå",
                A:"ÀÁÂ",
                e:"èéêë",
                E:"ÈÉÊË",
                i:"ìíîï",
                I:"ÌÍÎÏ",
                o:"òóôõöø",
                O:"ÒÓÔÕÖØ",
                u:"ùúûü",
                U:"ÙÚÛÜ",
                y:"ÿ",
                c: "ç",
                C:"Ç",
                n:"ñ",
                N:"Ñ"
            }; 
            var standard_str = '';
            var standard_arr = [];
            var normalized_arr = [];
            var regular_arr = [];
            var index = -1;
            for (let rule in rules) {
                standard_str = rules[rule];
                for (let character in standard_str.split('')) {
                    standard_arr.push(standard_str.split('')[character]);
                    normalized_arr.push(rule); 
                }
            }
            while (standard_arr[++index]) {
                regular_arr[standard_arr[index]] = normalized_arr[index];
            }
            regular_arr['Œ'] = 'OE';
            regular_arr['œ'] = 'oe';
            return text.replace(/./g, function(character){return (regular_arr[character]) ? regular_arr[character] : character});
        },
        clear_expand() {
                this.expanded_col=0;
                this.expanded_item={};
                this.expanded_row=0;
                this.expanded_table=[];
        },
        clear_filters() {
            this.clear_expand();
            this.filter_disponible = false;
            this.filter_duration = 120;
            this.filter_search = null;
            this.filter_sort_by = 'nom';
            this.filter_sort_desc = false;
            this.filter_per_page = 12;
        },
        custom_filters(items, search) {
            const formated_search = search ? this.clear_accent(search.toLowerCase()) : ''
            return items.filter((item) => {
                const key_arr = Object.keys(item)
                return (this.filter_disponible ? item.disponible === this.filter_disponible : true)
                && item.duree <= this.filter_duration 
                && (key_arr.filter((key) => {
                    if (formated_search === '') { return true } 
                    return (typeof item[key] === 'string' && key === 'nom') ? 
                    item[key].toLowerCase().includes(formated_search)
                    : false
                }).length!==0 
            )})
        },
        custom_filters_table(item) {
            return item != null
        },
        decrease_filter_duration() {
            this.filter_duration = (this.filter_duration - 10) || 0
        },
        display_duration(duration) {
            var rule = new RegExp("^[0-9]+$","g");
            if (rule.test(duration)) {
                var hours = parseInt(duration / 60);
                var minutes = (duration % 60);
                if(minutes == 0){return(hours + "h");}
                else{return (hours + "h" + minutes);}
            }
        },
        expand (item, row, col) {
            if (this.expanded_row == row && this.expanded_col == col) {
                this.expanded_item={};
                this.expanded_row=0;
                this.expanded_col=0;

            } else {
                this.expanded_item = item;
                this.expanded_row=row;
                this.expanded_col=col;
            }
        },
        increase_filter_duration() {
            this.filter_duration = (this.filter_duration + 10) || 180
        },
        is_expanded(item, row, col) {
            if (this.expanded_row == row && this.expanded_col == col) {
                return true;
            } else {
                return false;
            }
        },
        next_page() {
            this.clear_expand();
            this.filter_page = Math.min((this.filter_page + 1), this.pages)
        },
        per_page_update (number) {
            this.clear_expand();
            this.filter_per_page = number
        },
        previous_page() {
            this.clear_expand();
            this.filter_page !== 0 ? this.filter_page = Math.max((this.filter_page - 1), 1) : this.filter_page = 0
        },
        rows(items) {
            return Math.ceil(items.length / this.per_row)
        },       
        structure(items, row, col) {
            return items.filter((item, index) => {
                return (index>=col*(row-1) && index<col*(row-1)+col);
            });
        }
    }
}

</script>

<style>

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
    border: none !important;
}

.v-toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    z-index: 2;
}

</style>